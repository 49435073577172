import { ApplicationInsights } from '@microsoft/applicationinsights-web';

export const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: global.BROWSER_AI_KEY
  }
});

export function initAppInsights() {
  appInsights.loadAppInsights();
  appInsights.trackPageView();
}
