import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    axios
      .post('/report-error', {
        message: error.message,
        stack: error.stack,
        componentStack: info.componentStack,
        url: window.location.href
      })
      .then(() => {
        window.location = '/404.html';
      })
      .catch(() => {
        window.location = '/404.html';
      });
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      return '';
    }

    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.element.isRequired
};
