import axios from 'axios';
import queryString from 'query-string';

function paramsSerializer(params) {
  return queryString.stringify(params);
}

export async function apiGet({ url, params }) {
  return axios.get(`${global.API_BASE_URL}${url}`, {
    paramsSerializer,
    params
  });
}

export async function apiPost({ url, params, data }) {
  return axios.post(`${global.API_BASE_URL}${url}`, data, {
    params,
    paramsSerializer
  });
}
