export const getConfig = () => ({
  widget_key: window.SURFLY_WIDGET_KEY,
  chat_box_color: '#333',
  splash: false,
  show_loading_screen: false,
  block_until_agent_joins: false,
  auto_restore: false,
  newurl: true,
  drawing_enabled: true,
  docked_only: false,
  allow_control_switching: true,
  agent_can_take_control: true,
  allow_pause: false,
  videochat: true,
  videochat_autostart: true,
  start_muted: false,
  sharing_button: false,
  filesharing: true,
  leader_redirect_url: 'https://suitsupply.com/'
});

export const initSurfly = async () => {
  return new Promise((resolve, reject) => {
    window.Surfly.init(getConfig(), initResult => {
      if (initResult.success) {
        resolve(initResult);
      } else {
        reject(initResult);
      }
    });
  });
};

export const startSurflySession = ({ params, onStart, onJoin }) => {
  const session = global.Surfly.session();
  session
    .on('session_started', function(ses) {
      onStart(ses);
    })
    .on('viewer_joined', function(ses, event) {
      // if the first viewer joins, redirect to a page of your choice
      if (event.count === 1) {
        onJoin(ses);
      }
    })
    .startLeader(null, params);

  return session;
};
