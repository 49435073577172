import queryString from 'query-string';

export function formQuery(q) {
  const qstr = queryString.stringify(q);
  return qstr ? `?${qstr}` : '';
}

export function parseQuery() {
  return queryString.parse(global.location.search);
}
