import React from 'react';
import { Spinner } from '../Spinner/Spinner';
import { AgentMeeting } from './AgentMeeting';
import { parseQuery, formQuery } from '../../helpers/url-helper';
import { initSurfly, startSurflySession } from '../../helpers/surfly-helper';
import { getMeeting, INVALID_MEETING_STATUSES } from '../../helpers/sf-helper';

import './Home.css';
import { appInsights } from '../../helpers/ai-helper';

export class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showSpinner: true,
      showAgent: false,
      showReg: false,
      showJoin: false,
      meeting: null
    };
  }

  componentDidMount() {
    this.initSurfly();
  }

  initSurfly = async () => {
    await initSurfly();
    appInsights.trackEvent({ name: 'Surfly.Initialized' });
    const query = parseQuery();
    if (global.Surfly.isInsideSession) {
      // session started show a loading screen till follower joins
      this.setState({
        showSpinner: true,
        showJoin: true
      });
      appInsights.trackEvent({ name: 'Surfly.InsideSession' });
    } else if (query.agent_id) {
      // agent flow
      appInsights.trackEvent({ name: 'Reachout.AgentFlow' });
      this.setState({ showAgent: true, showSpinner: false });
    } else if (query.meeting_id) {
      // meeting flow
      const meeting = await getMeeting(query.meeting_id);
      if (!meeting || INVALID_MEETING_STATUSES.includes(meeting.status)) {
        appInsights.trackEvent(
          { name: 'Reachout.MeetingFlowInvalid' },
          { meetingId: query.meeting_id }
        );
        delete query.meeting_id;
        window.location.assign(`/${formQuery(query)}`);
        return;
      }
      appInsights.trackEvent(
        { name: 'Reachout.MeetingFlow' },
        { meetingId: query.meeting_id }
      );
      this.setState({ showAgent: true, showSpinner: false, meeting });
    } else {
      this.setState({
        showSpinner: false,
        showReg: true
      });
    }
  };

  _startMeeting = session => {
    appInsights.trackEvent({ name: 'Reachout.StartMeeting' });
    this.setState({ showSpinner: false, showJoin: false });
    session.relocate(global.MEETING_ROOM_URL);
  };

  startAgentMeeting = () => {
    const { meeting } = this.state;
    const params = parseQuery();
    if (params.meeting_id) {
      params.name = meeting.name;
    }
    appInsights.trackEvent(
      { name: 'Reachout.AppointmentFlowStarted' },
      { meeting }
    );
    startSurflySession({
      params,
      onStart: session => {
        console.log('session started', session);
        appInsights.trackEvent(
          { name: 'Surfly.SessionStarted' },
          { sessionId: session._sessionId }
        );
        if (!session.node) {
          this._startMeeting(session);
        }
      },
      onJoin: this._startMeeting
    });
    this.setState({ showJoin: true, showSpinner: false, showAgent: false });
  };

  render() {
    const { showSpinner, showAgent, showJoin, showReg } = this.state;
    return (
      <>
        <div className="header">
          <h3 style={{ marginBottom: 0 }}>
            <img src="https://suitsupply-expert-static.azureedge.net/images/suitsupply-logo.svg" />
          </h3>
          <p>Meeting Room</p>
        </div>
        <div className="main-container">
          {showReg && (
            <div className="customer-start text-container hidden">
              <h3>
                This Virtual Shopping session has expired.
                <br />
                Please contact your style advisor for further assistance
                <br />
                or book a new appointment online.
              </h3>

              <div style={{ marginTop: '30px' }}>
                <a
                  className="btn btn-primary"
                  href="https://suitsupply.com/stores"
                >
                  Book Appointment
                </a>
              </div>
            </div>
          )}
          {showAgent && <AgentMeeting onYes={this.startAgentMeeting} />}
          {showJoin && (
            <div className="text-container">
              <h3>
                Please wait until a Suitsupply Styling Expert joins you...
                <br />
                This can take a few minutes. Feel free to keep browsing in
                another tab.
              </h3>
            </div>
          )}
          {showSpinner && <Spinner />}
        </div>
      </>
    );
  }
}
