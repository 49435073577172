import { apiGet, apiPost } from './api-helper';

export async function checkOnlineAgents() {
  const { data } = await apiGet({ url: '/api/sf-check-online' });
  return data && data.records && data.records.length > 0;
}

export async function getCustomer(cn) {
  const { data } = await apiGet({ url: `/api/sf-check-cn/${cn}` });
  return data && data.length > 0 && { cn, name: data[0].Name };
}

export async function saveReachout({
  formData,
  customer,
  hasAgentsOnline,
  session
}) {
  if (hasAgentsOnline) {
    return;
  }

  const data = {
    type: hasAgentsOnline ? 'on-demand' : 'contact-requested'
  };
  if (customer) {
    data.customernumber = customer.cn;
    data.name = customer.name;
  }
  if (formData) {
    data.name = formData.fullName;
    data.email = formData.email;
  }

  if (session) {
    data.sessionId = session._sessionId;
    data.followerLink = session.followerLink;
  }

  await apiPost({
    url: '/api/reachout',
    data
  });
}

export async function getMeeting(meetingId) {
  const {
    data: { records }
  } = await apiGet({ url: `/api/meeting/${meetingId}` });
  return (
    records &&
    records.length > 0 && {
      Id: meetingId,
      status: records[0].Status__c,
      name: records[0].Account__r.Name
    }
  );
}

export const INVALID_MEETING_STATUSES = ['No interest', 'Completed', 'Ignored'];
