import React from 'react';

import './Spinner.css';

export const Spinner = () => (
  <div className="spinner">
    <div className="double-bounce1" />
    <div className="double-bounce2" />
  </div>
);
