/**
 * THIS IS THE ENTRY POINT FOR THE CLIENT, JUST LIKE server.js IS THE ENTRY POINT FOR THE SERVER.
 */
import 'regenerator-runtime/runtime';
import 'core-js/features/map';
import 'core-js/features/set';
import 'core-js/features/promise';
import 'core-js/features/object';
import 'core-js/features/array';
import 'core-js/features/symbol';

import React from 'react';
import { render } from 'react-dom';
import { Home } from './components/Home/Home';
import { ErrorBoundary } from './components/ErrorBoundary/ErrorBoundary';
import { initAppInsights } from './helpers/ai-helper';

import './styles/common.css';

const dest = document.getElementById('reachout-app');
initAppInsights();
render(
  <ErrorBoundary>
    <Home />
  </ErrorBoundary>,
  dest
);
