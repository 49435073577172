import React from 'react';

export const AgentMeeting = ({ onYes }) => (
  <div className="co-browse-start text-container hidden">
    <h3>Would you like to start a session with a Suitsupply Styling Expert?</h3>
    <button
      className="btn btn-primary"
      onClick={onYes}
      style={{ marginRight: '10px' }}
    >
      Yes
    </button>
    <button
      className="btn btn-default"
      onClick={() => {
        global.location.assign('https://suitsupply.com/');
      }}
    >
      Cancel
    </button>
  </div>
);
